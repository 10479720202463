/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

export const onServiceWorkerActive = ({serviceWorkerRegistration, options}) => {
	if(!window.JR) window.JR = {};
	if(!window.JR.swr) window.JR.swr = serviceWorkerRegistration;
	console.log(`onServiceWorkerActive called for JR: serviceWorkerRegistration, options`);
	console.log(serviceWorkerRegistration, options);
	console.log(`here is window.JR.swr:`, window.JR.swr);
};

export const onServiceWorkerInstalled = ({serviceWorkerRegistration, options}) => {
	if(!window.JR) window.JR = {};
	if(!window.JR.swr) window.JR.swr = serviceWorkerRegistration;
	console.log(`onServiceWorkerInstalled called for JR: serviceWorkerRegistration, options`);
	console.log(serviceWorkerRegistration, options);
	console.log(`here is window.JR.swr:`, window.JR.swr);
};
